<template>
  <App
    title="操作记录"
    left-arrow
  >
    <div class="record-list">
      <div
        v-for="(item, index) in recordList"
        :key="index"
        class="record-item"
      >
        <h5 class="name">{{item.operateUserName}}</h5>
        <div class="time">{{item.updatetime}}</div>
        <div class="action">
          <a href="javascript:;">{{item.operateDetail}}</a>
        </div>
      </div>
    </div>
  </App>
</template>
<script>
import dayjs from 'dayjs'
import { logList } from '@/api/testdrive/index'
import { Toast } from 'vant'
export default {
  name: 'testDriveOperatingRecord',
  data () {
    return {
      recordList: [],
      id: '' //试驾排程id
    }
  },
  methods: {
    // 获取页面传递过来的排程的id传递的参数名叫id
    getPageId () {
      this.id = this.$route.query.id
    },
    // 获取操作记录列表
    async getLogoList () {
      Toast.loading({
        message: 'Loading...',
        forbidClick: true,
        loadingType: 'spinner',
        overlay: true
      })
      const res = await logList({
        'testDriveId': this.id, //试驾排程ID
        page: 1,
        size: 10
      })
      Toast.clear()
      if (res.success) {
        res.data.records.forEach(el => {
          el['updatetime'] = dayjs(el['updatetime']).format('YYYY/MM/DD HH:mm:ss')
        })
        this.recordList = res.data.records
      } else {
        Toast(res.msg)
      }
    },
    init () {
      this.getPageId()
      this.getLogoList()
    }
  },
  mounted () {
    this.init()
  }
}
</script>
<style lang="less" scoped>
.record-list {
  position: relative;
  margin-top: 16px;
  background-color: #fff;

  .record-item {
    position: relative;
    padding: 26px 25px 30px;

    &:not(:last-child)::after {
      content: "";
      position: absolute;
      left: 25px;
      right: 0;
      bottom: 0;
      height: 1px;
      background-color: #e4e4e4;
      transform: scale(1, 0.5);
    }

    .name {
      font-size: 32px;
      line-height: 45px;
      margin-bottom: 16px;
    }

    .time {
      font-size: 24px;
      color: #666;
      line-height: 33px;
    }
  }

  .action {
    position: absolute;
    top: 23px;
    right: 23px;

    a {
      font-size: 22px;
      color: #666;
    }
  }
}
</style>